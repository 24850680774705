import React from 'react';
import {Redirect, Route, Switch, withRouter} from 'react-router-dom';
import asyncComponent from '../../../util/asyncComponent';
import {connect} from "react-redux";

const Dashboard = ({match,globalSettings}) => {
  if(globalSettings.label && ['VAMOSETH', 'HABESHAETH', 'JEGINA', 'DASH'].includes(globalSettings.label.project)) {
    return (
        <div className="app-wrapper">
          <Switch>
            <Redirect exact from={`${match.url}/`} to={`${match.url}/dashboard`}/>
            <Route path={`${match.url}/dashboard`} component={asyncComponent(() => import('./routes/Dashboard'))}/>
            <Route path={`${match.url}/sports`} component={asyncComponent(() => import('./routes/Sports'))}/>
            {['DASH'].includes(globalSettings.label.project) && 
              <Route path={`${match.url}/inPlay`} component={asyncComponent(() => import('./routes/InPlay'))}/>
            }
            <Route path={`${match.url}/finance`} component={asyncComponent(() => import('./routes/Finance'))}/>
            <Route path={`${match.url}/pay-out`} component={asyncComponent(() => import('./routes/Payouts'))}/>
            <Route path={`${match.url}/ticket-payouts`} component={asyncComponent(() => import('./routes/TicketPayouts'))}/>
            <Route path={`${match.url}/bets`} component={asyncComponent(() => import('./routes/Bets'))}/>
            <Route path={`${match.url}/results`} component={asyncComponent(() => import('./routes/Results'))}/>
            <Route path={`${match.url}/website`} component={asyncComponent(() => import('./routes/Website'))}/>
            <Route path={`${match.url}/downloads`} component={asyncComponent(() => import('./routes/Downloads'))}/>
            <Route path={`${match.url}/jackpot`} component={asyncComponent(() => import('./routes/Totalizator'))}/>
            <Route path={`${match.url}/search/:type/:query`} component={asyncComponent(() => import('./routes/SearchEvent'))}/>
            {globalSettings.label.statisticEnabled &&
              <Route path={`${match.url}/statistics`} component={asyncComponent(() => import('./routes/Statistics'))}/> 
            }
            <Route component={asyncComponent(() => import(__dirname + '/../extraPages/routes/404'))}/>
          </Switch>
        </div>
    )
  } else if (globalSettings.label && ['THRONEBET', 'PREMIERUG'].includes(globalSettings.label.project)) {
    return (
      <div className="app-wrapper">
          <Switch>
            <Redirect exact from={`${match.url}/`} to={`${match.url}/dashboard`}/>
            <Route path={`${match.url}/dashboard`} component={asyncComponent(() => import('./routes/Dashboard'))}/>
            <Route path={`${match.url}/finance`} component={asyncComponent(() => import('./routes/Finance'))}/>
            <Route component={asyncComponent(() => import(__dirname + '/../extraPages/routes/404'))}/>
          </Switch>
        </div>
    )
  } else {
    return (
        <div className="app-wrapper">
          <Switch>
            {/* <Redirect exact from={`${match.url}/`} to={`${match.url}/${globalSettings.label.project === 'BWANABET' ? 'dashboard' : 'sports'}`}/> */}
            {globalSettings.label.project === 'BWANABET' && 
             <Route path={`${match.url}/dashboard`} component={asyncComponent(() => import('./routes/Dashboard'))}/>
            }
            <Route path={`${match.url}/sports`} component={asyncComponent(() => import('./routes/Sports'))}/>
            <Route path={`${match.url}/live`} component={asyncComponent(() => import('./routes/Live'))}/>
            <Route path={`${match.url}/inPlay`} component={asyncComponent(() => import('./routes/InPlay'))}/>
            <Route path={`${match.url}/keno`} component={asyncComponent(() => import('./routes/Keno'))}/>
            <Route path={`${match.url}/dogs`} component={asyncComponent(() => import('./routes/Dogs'))}/>
            <Route path={`${match.url}/kaboom`} component={asyncComponent(() => import('./routes/Kaboom'))}/>
            <Route path={`${match.url}/horses`} component={asyncComponent(() => import('./routes/Horses'))}/>
            <Route path={`${match.url}/wof`} component={asyncComponent(() => import('./routes/WOF'))}/>
            {/*{(globalSettings.label && (globalSettings.label.project === 'PMBETTZ' || globalSettings.label.project === 'PMBETKE')) &&*/}
            {/*  <Route path={`${match.url}/virtual-football`} component={asyncComponent(() => import('./routes/VirtualFootball'))}/>*/}
            {/*}*/}
            <Route path={`${match.url}/finance`} component={asyncComponent(() => import('./routes/Finance'))}/>
            <Route path={`${match.url}/pay-out`} component={asyncComponent(() => import('./routes/Payouts'))}/>
            <Route path={`${match.url}/ticket-payouts`} component={asyncComponent(() => import('./routes/TicketPayouts'))}/>
            <Route path={`${match.url}/bets`} component={asyncComponent(() => import('./routes/Bets'))}/>
            <Route path={`${match.url}/results`} component={asyncComponent(() => import('./routes/Results'))}/>
            <Route path={`${match.url}/website`} component={asyncComponent(() => import('./routes/Website'))}/>
            <Route path={`${match.url}/downloads`} component={asyncComponent(() => import('./routes/Downloads'))}/>
            <Route path={`${match.url}/jackpot`} component={asyncComponent(() => import('./routes/Totalizator'))}/>
            <Route path={`${match.url}/search/:type/:query`} component={asyncComponent(() => import('./routes/SearchEvent'))}/>
            <Route component={asyncComponent(() => import(__dirname + '/../extraPages/routes/404'))}/>
          </Switch>
        </div>
    )
  }
};

const mapStateToProps = ({settingsReducer}) => {
  const {globalSettings} = settingsReducer;
  return {globalSettings}
};
export default withRouter(connect(mapStateToProps)(Dashboard));
