export const API_URL = process.env.REACT_APP_API_URL;
export const LIVE_SOCKET_URL = process.env.REACT_APP_LIVE_URL || 'https://live5.betunit.com';

export const labelHandler = (project) => {

    let labels = {
        PREMIERUG: {
            logo: {
                img:require('../assets/images/logos/pr/logoSignIn.svg'),
                signIn:require('../assets/images/logos/pr/logoSignIn.svg'),
                // print:require('../assets/images/logos/pr/logoPrint.svg'),
                title:'PremierBet',
                alt:'PremierBet',
                web:''
            },
        },
        THRONEBET: {
            logo: {
                img:require('../assets/images/logos/thr/logoSignIn.svg'),
                signIn:require('../assets/images/logos/thr/logoSignIn.svg'),
                print:require('../assets/images/logos/thr/logoPrint.svg'),
                title:'ThroneBet',
                alt:'ThroneBet',
                web:''
            },
        },
        DASH: {
            logo: {
                img:require('../assets/images/logos/db/logoHeader.svg'),
                signIn:require('../assets/images/logos/db/logoSignIn.svg'),
                print:require('../assets/images/logos/db/logoPrint.svg'),
                title:'DashBet',
                alt:'DashBet',
                web:''
            },
        },
        BWANABET: {
            logo: {
                img:require('../assets/images/logos/bwb/square.svg'),
                signIn:require('../assets/images/logos/bwb/logo-sb.svg'),
                print: require('../assets/images/logos/bwb/logo-sb.svg'),
                // print:require('../assets/images/logos/bwb/grayscale.png'),
                title:'Bwanabet',
                alt:'Bwanabet',
                web:''
            },
        },
        MAVERIX: {
            logo: {
                img:require('../assets/images/logos/mvx/square.png'),
                signIn:require('../assets/images/logos/mvx/logo-sb.svg'),
                print:require('../assets/images/logos/mvx/grayscale.png'),
                title:'Maveriks Zimbabwe',
                alt:'Zimbabwe',
                web:''
            },
        },
        PMBETTZ: {
            logo: {
                img:require('../assets/images/logos/tz/logoHeader.png'),
                signIn:require('../assets/images/logos/tz/logoSignIn.png'),
                print:require('../assets/images/logos/tz/logoPrint.png'),
                title:'PlayMasters Tanzania',
                alt:'Tanzania',
                web:''
            },
        },
        PMBETKE: {
            logo: {
                img:require('../assets/images/logos/ke/KenyaLogoSmall.svg'),
                signIn:require('../assets/images/logos/ke/KenyaLogo.png'),
                print:require('../assets/images/logos/ke/KenyaLogoPrint.png'),
                title:'PlayMasters Kenya',
                alt:'Kenya',
                web:''
            }
        },
        PMBETZM: {
            logo: {
                img:require('../assets/images/logos/zm/KenyaLogoSmall.svg'),
                signIn:require('../assets/images/logos/zm/ZambiaLogo.png'),
                print:require('../assets/images/logos/zm/ZambiaLogoPrint.png'),
                title:'PlayMasters Zambia',
                alt:'Zambia',
                web:''
            }
        },
        VAMOSETH: {
            logo: {
                img:require('../assets/images/logos/eth/AradaLogo.svg'),
                signIn:require('../assets/images/logos/eth/AradaLogo.svg'),
                print:require('../assets/images/logos/eth/AradaLogoPrint.png'),
                title:'Arada Ethiopia',
                alt:'Aradabets',
                web:'www.arada.bet'
            }
        },
        HABESHAETH: {
            logo: {
                img:require('../assets/images/logos/eth/FlashLogo.svg'),
                signIn:require('../assets/images/logos/eth/FlashLogo.svg'),
                print:require('../assets/images/logos/eth/FlashLogoPrint.png'),
                title:'Flashsport Ethiopia',
                alt:'Flashsport',
                web:'www.flashsport.bet'
            }
        },
        JEGINA: {
            logo: {
                img:require('../assets/images/logos/jg/JeginaLogo.png'),
                signIn:require('../assets/images/logos/jg/JeginaLogo.png'),
                print:require('../assets/images/logos/jg/JeginaLogo.png'),
                title:'Jegina Ethiopia',
                alt:'Jegina',
                web:'www.jegina.betunit.com'
            }
        }

    };

    if (labels[project]) {
        return labels[project]
    } else {
        return labels['PMBETTZ']
    }
};
