import React, {Component} from 'react';
import {NavLink, withRouter} from 'react-router-dom';
import IntlMessages from 'util/IntlMessages';
import {connect} from "react-redux";

class Menu extends Component {

    render() {
        const {globalSettings} = this.props;
        return (
            <div className="app-main-menu d-none d-md-block">
                <ul className="navbar-nav navbar-nav-mega">
                    {(globalSettings.label && ['VAMOSETH', 'HABESHAETH', 'JEGINA', 'DASH', 'THRONEBET', 'PREMIERUG'].includes(globalSettings.label.project))
                        ?<>
                            <li className="nav-item">
                                <NavLink className="prepend-icon" to="/app/dashboard/dashboard">
                                    <span className="nav-text"><IntlMessages id="Dashboard"/></span>
                                </NavLink>
                            </li>
                            {['DASH'].includes(globalSettings.label.project) &&  
                                <li className="nav-item">
                                    <NavLink className="prepend-icon" to="/app/dashboard/inPlay">
                                        <span className="nav-text">
                                            <IntlMessages id="In Play" />
                                        </span>
                                    </NavLink>
                                </li>
                            }
                            {!['JEGINA', 'THRONEBET', 'PREMIERUG'].includes(globalSettings.label.project) &&
                                <li className="nav-item">
                                    <NavLink className="prepend-icon" to="/app/dashboard/sports">
                                        <span className="nav-text"><IntlMessages id="Sports"/></span>
                                    </NavLink>
                                </li>
                            }
                        </>
                        :
                        <>
                            {/* <li className="nav-item">
                                <NavLink className="prepend-icon" to="/app/dashboard/live">
                                    <span className="nav-text"><IntlMessages id="Live"/></span>
                                </NavLink>
                            </li> */}
                            {globalSettings.label.project === 'BWANABET' ? 
                                <li className="nav-item">
                                    <NavLink className="prepend-icon" to="/app/dashboard/dashboard">
                                        <span className="nav-text">
                                            <IntlMessages id="Dashboard" />
                                        </span>
                                    </NavLink>
                                </li>: null
                            }
                            <li className="nav-item">
                                <NavLink className="prepend-icon" to="/app/dashboard/inPlay">
                                    <span className="nav-text">
                                        <IntlMessages id="In Play" />
                                    </span>
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="prepend-icon" to="/app/dashboard/sports">
                                    <span className="nav-text"><IntlMessages id="Sports"/></span>
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="prepend-icon" to="/app/dashboard/keno">
                                    <span className="nav-text"><IntlMessages id="Keno"/></span>
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="/app/dashboard/wof">
                                    <span className="nav-text"><IntlMessages id="WOF"/></span>
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="/app/dashboard/kaboom">
                                    <span className="nav-text"><IntlMessages id="Kaboom"/></span>
                                </NavLink>
                            </li>
                            {/* <li className="nav-item">
                                <NavLink className="prepend-icon" to="/app/dashboard/dogs">
                                    <span className="nav-text"><IntlMessages id="Dogs"/></span>
                                </NavLink>
                            </li> */}
                            {/* <li className="nav-item"> */}
                                {/* <span className="nav-link"><IntlMessages id="Games"/><ExpandMore style={{fontSize: 20}}/></span>
                                <ul className="sub-menu"> */}
                                    {/* <li>
                                        <NavLink to="/app/dashboard/kaboom">
                                            <span className="nav-text"><IntlMessages id="Kaboom"/></span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/app/dashboard/horses">
                                            <span className="nav-text"><IntlMessages id="Horses"/></span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/app/dashboard/wof">
                                            <span className="nav-text"><IntlMessages id="WOF"/></span>
                                        </NavLink>
                                    </li> */}
                                    {/*<li>*/}
                                    {/*    <NavLink to="/app/dashboard/virtual-football">*/}
                                    {/*        <span className="nav-text"><IntlMessages id="Virtual Football"/></span>*/}
                                    {/*    </NavLink>*/}
                                    {/*</li>*/}
                                {/* </ul> */}
                            {/* </li> */}
                        </>
                    }
                    {(globalSettings.label && (globalSettings.label.project === 'HABESHAETH'))
                        ? <>
                        </> :
                        <>
                            <li className="nav-item">
                                <NavLink className="prepend-icon" to="/app/dashboard/finance">
                                    <span className="nav-text"><IntlMessages id="Finance"/></span>
                                </NavLink>
                            </li>
                            {!['JEGINA', 'THRONEBET', 'PREMIERUG'].includes(globalSettings.label.project) &&
                                <li className="nav-item">
                                    <NavLink className="prepend-icon" to="/app/dashboard/pay-out">
                                        <span className="nav-text"><IntlMessages id="Payouts"/></span>
                                    </NavLink>
                                </li>
                            }
                        </>
                        }
                    {!['JEGINA', 'THRONEBET', 'PREMIERUG'].includes(globalSettings.label.project) &&
                        <li className="nav-item">
                            <NavLink className="prepend-icon" to="/app/dashboard/ticket-payouts">
                                <span className="nav-text"><IntlMessages id="Ticket Payouts"/></span>
                            </NavLink>
                        </li>
                    }
                    {(globalSettings.label && ['VAMOSETH', 'HABESHAETH', 'JEGINA', 'DASH', 'THRONEBET', 'PREMIERUG'].includes(globalSettings.label.project))
                        ?
                        <>
                        {!['JEGINA', 'THRONEBET', 'PREMIERUG'].includes(globalSettings.label.project) &&
                            <li className="nav-item">
                                <NavLink className="prepend-icon" to="/app/dashboard/website">
                                    <span className="nav-text"><IntlMessages id="Deposit"/></span>
                                </NavLink>
                            </li>
                        }
                        {(globalSettings.label && ['VAMOSETH', 'DASH'].includes(globalSettings.label.project)) ?
                            <li className="nav-item">
                                <NavLink className="prepend-icon" to="/app/dashboard/bets">
                                    <span className="nav-text" ><IntlMessages id="Bets"/></span>
                                </NavLink>
                            </li>
                            : <></>
                        }
                        </>
                        : <>
                            {globalSettings?.label?.project !== 'MAVERIX' && 
                                <li className="nav-item">
                                    <NavLink className="prepend-icon" to="/app/dashboard/website">
                                        <span className="nav-text"><IntlMessages id="Website"/></span>
                                    </NavLink>
                                </li>
                            }
                            <li className="nav-item">
                                <NavLink className="prepend-icon" to="/app/dashboard/bets">
                                    <span className="nav-text"><IntlMessages id="Bets"/></span>
                                </NavLink>
                            </li>
                        </>
                    }
                    {!['JEGINA', 'THRONEBET', 'PREMIERUG'].includes(globalSettings.label.project) &&
                        <>
                            <li className="nav-item">
                                <NavLink className="prepend-icon" to="/app/dashboard/results">
                                    <span className="nav-text"><IntlMessages id="Results"/></span>
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="prepend-icon" to="/app/dashboard/downloads">
                                    <span className="nav-text"><IntlMessages id="Downloads"/></span>
                                </NavLink>
                            </li>
                        </>
                    }
                    {(globalSettings.label && globalSettings.label.project === 'PMBETTZ') &&
                        <li className="nav-item">
                            <NavLink className="prepend-icon" to="/app/dashboard/jackpot">
                                <span className="nav-text"><IntlMessages id="Jackpot"/></span>
                            </NavLink>
                        </li>
                    }
                    {(globalSettings.label && (globalSettings.label.project === 'HABESHAETH')) &&
                    <>
                        <li className="nav-item">
                            <NavLink className="prepend-icon" to="/app/dashboard/finance">
                                <span className="nav-text" style={navBarHidden} ><IntlMessages id="Finance"/></span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="prepend-icon" to="/app/dashboard/pay-out">
                                <span className="nav-text" style={navBarHidden}><IntlMessages id="Payouts"/></span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="prepend-icon" to="/app/dashboard/bets">
                                <span className="nav-text" style={navBarHidden} ><IntlMessages id="Bets"/></span>
                            </NavLink>
                        </li>
                        {globalSettings.label.statisticEnabled && <li className="nav-item">
                            <NavLink className="prepend-icon" to="/app/dashboard/statistics">
                                <span className="nav-text"  style={navBarHidden}  ><IntlMessages id="Statistics"/></span>
                            </NavLink>
                        </li>}
                    </>
                    }
                </ul>
            </div>
        );
    }
}
const navBarHidden = {
    color: '#38434b'
};

const mapStateToProps = ({settingsReducer}) => {
    const {globalSettings} = settingsReducer;
    return {globalSettings}
};
export default withRouter(connect(mapStateToProps)(Menu));
